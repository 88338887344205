import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 定义全局loading 为false
    globalLoading: false
  },
  getters: {
  },
  mutations: {
    // 显示与关闭 全局 loading
    showLoading (state) {
      state.globalLoading = true
    },
    hideLoading (state) {
      state.globalLoading = false
    }
  },
  actions: {
  },
  modules: {
  }
})
