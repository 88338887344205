<template>
  <div id="app">
    <router-view></router-view>
     <loading v-show="globalLoading"></loading>
  </div>
</template>

<script>
import Loading from './components/GlobalLoad/GlobalLoad' // 全局Loading组件
import { mapState } from 'vuex' // 引入vuex状态管理，mapState管理中存在全局loading
export default {
  components: {
    Loading // 注册全局loading 组件
  },
  computed: {
    // 全局 loading
    ...mapState([
      'globalLoading'
    ])
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
