import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/payResult',
    name: 'payResult',
    component: () => import('@/views/payAuth/PayResult.vue')
  },
  {
    path: '/PayOrderCheck',
    name: 'PayOrderCheck',
    component: () => import('@/views/payAuth/PayOrderCheck.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
