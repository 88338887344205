import router from './router'

const allowList = ['home', 'PayAuthCode', 'PayOrderCheck', 'payResult']

router.beforeEach((to, from, next) => {
  if (allowList.includes(to.name)) {
    next()
  } else {
    next({ path: '/' })
  }
})
