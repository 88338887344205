import Vue from 'vue'
import {
  Button,
  Divider,
  Card,
  Radio,
  Input,
  InputNumber,
  Form,
  FormModel,
  Select,
  Modal,
  Result,
  Spin,
  message,
  notification
} from 'ant-design-vue'

Vue.use(Button)
Vue.use(Divider)
Vue.use(Card)
Vue.use(Radio)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Select)
Vue.use(Modal)
Vue.use(Result)
Vue.use(Spin)

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
